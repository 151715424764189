<template>
  <div id="top" class="w-full">
    <div class="flex justify-end">
      <div
        id="menu"
        class="fixed top-0 z-40 px-2 pt-3 lg:pt-8 lg:px-8"
        :class="{ active: toggleMenu }"
        @click="openMenu"
      >
        <button type="button" class="menu-button" title="Menu">
          <span class="hidden">Menu</span>
          <span class="menu-bar menu-bar--1"></span>
          <span class="menu-bar menu-bar--2"></span>
          <span class="menu-bar menu-bar--3"></span>
        </button>
      </div>
    </div>
    <transition
      name="fade"
      mode="out-in"
      enter-active-class="animate__animated animate__fadeInDown"
      leave-active-class="animate__animated animate__fadeOutUp"
    >
      <div
        v-if="toggleMenu"
        class="fixed top-0 z-30 flex items-center w-full lg:h-screen menu-bg"
      >
        <div class="container flex py-24 mx-auto md:flex-col lg:py-40">
          <div class="w-full px-5 text-2xl lg:w-1/2 lg:text-4xl">
            <ul class="leading-relaxed">
              <li class="md:text-center">
                <a
                  @click.prevent="pushTo('/')"
                  href="/"
                  class="font-bold text-gray-200 no-underline  hover:text-red-600"
                  >Start</a
                >
              </li>
              <li class="md:text-center">
                <a
                  @click.prevent="pushTo('/#filozofia')"
                  href="/#filozofia"
                  class="font-bold text-gray-200 no-underline  hover:text-red-600"
                  >Filozofia</a
                >
              </li>
              <li class="md:text-center">
                <a
                  @click.prevent="pushTo('/diagnostyka')"
                  href="/diagnostyka"
                  class="font-bold text-gray-200 no-underline  hover:text-red-600"
                  >Diagnostyka</a
                >
              </li>
              <li class="md:text-center">
                <a
                  @click.prevent="pushTo('/inspiracja')"
                  href="/inspiracja"
                  class="font-bold text-gray-200 no-underline  hover:text-red-600"
                  >Inspiracja</a
                >
              </li>
              <li class="md:text-center">
                <a
                  @click.prevent="pushTo('/miejsce-odkrycia')"
                  href="/miejsce-odkrycia"
                  class="font-bold text-gray-200 no-underline  hover:text-red-600"
                  >Miejsce odkrycia</a
                >
              </li>
              <li class="md:text-center">
                <a
                  @click.prevent="pushTo('/indywidualne-odkrycie')"
                  href="/indywidualne-odkrycie"
                  class="font-bold text-gray-200 no-underline  hover:text-red-600"
                  >Indywidualne odkrycie</a
                >
              </li>
              <li class="md:text-center">
                <a
                  @click.prevent="pushTo('/kontakt')"
                  href="/kontakt"
                  class="font-bold text-gray-200 no-underline  hover:text-red-600"
                  >Dlaczego good way i kim jestem?</a
                >
              </li>
              <li class="md:text-center">
                <a
                  @click.prevent="pushTo('/blog')"
                  href="/blog"
                  class="font-bold text-gray-200 no-underline  hover:text-red-600"
                  >Blog</a
                >
              </li>
            </ul>
          </div>
          <div class="flex items-center w-full px-5 lg:w-1/2 md:justify-center">
            <ul class="flex md:p-4">
              <li>
                <a
                  href="https://www.facebook.com/goodwaylifediscovery/"
                  target="_blank"
                  class="text-gray-200 no-underline hover:text-red-600"
                  rel="noopener noreferrer"
                >
                  <svg
                    class="w-6 m-2 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    x="0"
                    y="0"
                    viewBox="0 0 600 600"
                    xml:space="preserve"
                  >
                    <title>Opis facebook</title>
                    <path
                      d="M4 564.9C4 388.3 4 211.7 4 35.1c0.3-1.2 0.8-2.3 1-3.5C7.4 17.4 17.4 7.4 31.6 5c1.2-0.2 2.3-0.6 3.5-1 176.6 0 353.2 0 529.8 0 21 5.9 24.9 9.3 31.1 31.1 0 176.8 0 353.6 0 530.3 -7.4 22.6-9.1 23.5-30.6 30.6 -50.8 0-101.6 0-152.4 0 0.1-7.7 0.1 22.9 0.1-229.3 84.1 0 76.7 1.9 77.5-4.7 10.9-91.4 13.2-84.9 7.3-84.9 -31.9 0-58.6 0-84.6 0 0-0.2-1.2-60.1 1.6-73.8 9.1-45.6 89.8-15.8 89.7-29 -0.6-86 5.3-78.3-13.5-79 -54.7-2-125.7-12.7-159 56.5 -14.9 31-12.2 53.7-12.2 125.4 -82.1 0-77.6-1.4-77.6 3.1 0.3 94.6-1.8 86.5 3.4 86.5 22 0 52.1 0 74.2 0 0 71.4 0 158 0 225.6 0 1.2-0.1 2.5-0.1 3.7 -95 0-190.1 0-285.1 0C14.5 589.4 10.4 587.9 4 564.9z"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/krzysztof-janowski-b34a1555/"
                  target="_blank"
                  class="text-gray-200 no-underline hover:text-red-600"
                  rel="noopener noreferrer"
                >
                  <svg
                    class="w-6 m-2 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 20 20"
                  >
                    <title>GOOD WAY Krzysztof Janowski</title>
                    <path
                      d="M1,6h4v13H1V6z M3,1C1.8,1,1,2,1,3.1C1,4.1,1.8,5,3,5c1.3,0,2-0.9,2-2C5,1.9,4.2,1,3,1z M14.6,6.2  c-2.1,0-3.3,1.2-3.8,2h-0.1l-0.2-1.7H6.9C6.9,7.6,7,8.9,7,10.4V19h4v-7.1c0-0.4,0-0.7,0.1-1c0.3-0.7,0.8-1.6,1.9-1.6  c1.4,0,2,1.2,2,2.8V19h4v-7.4C19,7.9,17.1,6.2,14.6,6.2z"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCxbEWgpU30VL5_wMyy7S88g"
                  target="_blank"
                  class="text-gray-200 no-underline hover:text-red-600"
                  rel="noopener noreferrer"
                >
                  <svg
                    class="w-6 m-2 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    x="0"
                    y="0"
                    viewBox="0 0 600 600"
                    xml:space="preserve"
                  >
                    <title>GOOD WAY Life Discovery</title>
                    <path
                      d="M592.8 215.7c-4.1-56.4-14.5-114.7-87.5-118.9 -133.5-7.7-277.1-7.7-410.6 0 -73 4.2-83.4 62.5-87.5 118.9 -4.3 58.4-4.3 110.1 0 168.6 4.1 56.4 14.5 114.7 87.5 118.9 133.5 7.7 277.1 7.7 410.6 0 73-4.2 83.4-62.5 87.5-118.9C597.1 325.8 597.1 274.2 592.8 215.7zM239.6 377V210.7l158.5 83.2L239.6 377z"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="http://www.instagram.com/krzysztofjanowski"
                  target="_blank"
                  class="text-gray-200 no-underline hover:text-red-600"
                  rel="noopener noreferrer"
                >
                  <svg
                    class="w-6 m-2 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    x="0"
                    y="0"
                    viewBox="0 0 600 600"
                    xml:space="preserve"
                  >
                    <title>Mój instagram</title>
                    <path
                      d="M420.4 596H179.6C87.4 596 12 520.6 12 428.4V171.6C12 79.4 87.4 4 179.6 4h240.8C512.6 4 588 79.4 588 171.6v256.8C588 520.6 512.6 596 420.4 596zM420.4 551.7c32.7 0 63.7-12.9 87-36.3 23.4-23.4 36.3-54.3 36.3-87.1V171.6c0-32.8-12.9-63.7-36.3-87.1s-54.3-36.3-87-36.3H179.6c-32.7 0-63.7 12.9-87 36.3s-36.3 54.3-36.3 87.1v256.7c0 32.8 12.9 63.7 36.3 87.1 23.4 23.4 54.3 36.3 87 36.3H420.4zM459.5 297.7c0 87.6-71 158.7-158.6 158.7s-158.6-71-158.6-158.7S213.3 139 300.9 139 459.5 210.1 459.5 297.7zM300.1 193.9c-57.4 0-103.9 46.5-103.9 103.9s46.5 103.9 103.9 103.9S404 355.2 404 297.8 357.5 193.9 300.1 193.9z"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      toggleMenu: false,
    };
  },
  methods: {
    openMenu() {
      this.toggleMenu = !this.toggleMenu;
    },
    pushTo(id) {
      this.$router.push(id);
      this.toggleMenu = false;
    },
  },
};
</script>
